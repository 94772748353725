import React from 'react'
import Seo from "../../components/seo/index";
import Layout from "./layout/index";
import BasicForCompanyRegister from './components/basicFormCompanyRegister'

const AddParticipant = () => {
  return (
    <div>
    <Seo title="Green Heartfulness Run 2024" />
    <Layout isSticky>
      <BasicForCompanyRegister />
    </Layout>
  </div>
  )
}

export default AddParticipant